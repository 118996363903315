import * as React from 'react';

import SEO from '../components/Seo/Seo';
import ColorLogo from '../images/color-logo.png';

const OfertaPage: React.SFC<{}> = (props): JSX.Element => {
    return (
        <>
            <SEO
                keywords={[]}
                meta={[{
                    name: 'robots',
                    content: 'noindex',
                }]}
            />

            <section className="main-screen confidential">
                <div className="container">
                    <div className="main-screen-content">

                        <img alt="Revat" src={ColorLogo} />
                        <div className='message-wrapper'>
                            <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ</h1>
                            <h2>Загальні положення</h2>
                            <p>Персональні дані – відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована.</p><br/>
                            <p>В цій Політиці конфіденційності описується, які Ваші персональні дані збираються і обробляються ФОП Череватий Ю. Л. після входу на веб-сайт revatgym.com/ . Для того щоб використовувати веб-сайт revatgym.com/ , Користувач повинен уважно ознайомитися з даною Політикою конфіденційності, а використання веб-сайту revatgym.com/  є висловленням повної згоди з її умовами.</p><br/>
                            <p>Будь ласка, уважно прочитайте цю Політику конфіденційності, щоб зрозуміти наші погляди і практику щодо Ваших персональних даних, перш ніж продовжувати відвідування цього веб-сайту.</p><br/>

                            <h2>Мета збору та обробки персональних даних</h2>
                            <p>Нам потрібні ваші персональні дані, щоб відповісти, якщо ви зв’яжетесь із нами, надсилати вам деякі періодичні маркетингові матеріали, такі як інформаційні бюлетені, офіційні документи або інформацію про наші професійні послуги.</p><br/>
                            <p>Також, ми збираємо особисту інформацію наших Користувачів для того, щоб:</p><br/>
                            <ul>
                                <li>підтримувати і покращувати наш веб-сайт, робити його більш зручним і простим у використанні;</li>
                                <li>відповідати на рекомендації Користувачів і скарги;</li>
                                <li>для інших адміністративних і внутрішніх цілей.</li>
                            </ul><br/>

                            <h2>Інформація, яка підлягає збору, зберіганню та використанню</h2>
                            <p>За допомогою форми зворотнього зв’язку, розміщеної на нашому веб-сайті, ми збираємо наступні персональні дані: Ваш номер телефону, адресу електронної пошти та Ваше ім’я.</p><br/>

                            <h2>Термін зберігання особистої інформації</h2>
                            <p>Ми зберігаємо Вашу інформацію до тих пір, поки це необхідно, щоб надати Вам необхідні сервіси і щоб виконати наші зобов’язання відповідно до цієї політики конфіденційності та згідно із законом.</p><br/>
                            <p>Крім того, заповнивши форму запиту і відправивши її нам за електронною адресою hello@revatgym.com , Ви завжди можете:</p>
                            <ul>
                                <li>вимагати видалення чи виправлення Вашої особистої інформації;</li>
                                <li>отримати доступ до Ваших персональних даних;</li>
                                <li>вимагати передачі  Ваших персональних даних третім особам.</li>
                            </ul><br/>

                            <h2>Захист та зберігання зібраної інформації</h2>
                            <p>Ми дотримуємось міжнародних стандартів контролю, спрямованих на організацію операцій з особистою інформацією. Вони включають різноманітні адміністративні, управлінські та технічні заходи безпеки для захисту конфіденційності даних, зібраних в Інтернеті. Всі дані, які ми збираємо, зберігаються на захищених серверах баз даних і не можуть бути доступні поза межами нашої корпоративної мережі.</p><br/>
                            <p>Проте, Користувач підтверджуєте своє ознайомлення з тим фактом, що передача інформації через Інтернет є по суті незахищеною, і ми не можемо гарантувати захист даних, надісланих через Інтернет.</p><br/>
                            <p>Тільки ті співробітники ФОП Череватий Ю. Л., яким необхідно виконувати робочі функції, для яких потрібен доступ до особистої інформації Користувачів, мають доступ до такої інформації. Всі співробітники, що мають доступ до інформації про Користувачів, підписали угоду про нерозголошення інформації третім особам.</p><br/>

                            <h2>Порядок доступу третіх осіб</h2>
                            <p>Ми не передаємо персональні дані третім особам, за винятком випадків, передбачених в цій Політиці конфіденційності. Користувач, погоджуючись з цією Політикою конфіденційності, надає право ФОП Череватий Ю. Л. розкривати свої персональні дані третім особам, які надають послуги ФОП Череватий Ю. Л. . Треті особи можуть використовувати особисту інформацію тільки у тому випадку, якщо вони надають послуги ФОП Череватий Ю. Л. і лише ту інформацію, яка необхідна для надання послуги.</p>
                            <p>Також розкриття персональних даних без згоди Користувача або уповноваженої ним особи, допускається у випадках, визначених законом, і лише в інтересах національної безпеки, економічного добробуту та прав людини, зокрема, але не виключно за обгрунтованими запитами державних органів, які мають право затребувати і отримувати такі дані.</p><br/>

                            <h2>Зміни та поправки</h2>
                            <p>Ми лишаємо за собою право періодично вносити зміни та поправки в Політику конфіденційності, та публікувати її нову редакцію на нашому сайті. Ви повинні періодично перевіряти цю веб-сторінку, щоб пересвідчитись, що розумієте зміст змін, внесених до цієї Політики конфіденційності.</p><br/>

                            <h2>Контактна адреса</h2>
                            <p>У разі виникнення питань, побажань, скарг щодо інформації, яку ви надаєте, будь ласка, звертайтесь за контактною електронною адресою: hello@revatgym.com .</p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OfertaPage
